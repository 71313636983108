import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoSchoolsUniversities from '../images/uploads/Schools_Universities_shutterstock_64258234.jpg';

const SchoolsUniversities = () => {
  return (
    <Layout pageSlug="schools-universities">
      <SEO title="Schools &amp; Universities" />
      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>Schools &amp; Universities</h3>
                  </div>
                  <div className="inner-margin-top-minus-60">
                    {
                      <img
                        src={ShutterstockPhotoSchoolsUniversities}
                        alt="Schools &amp; Universities"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col l16 m16 s24">
                <div className="box bs">
                  <h2>Making payment simple.</h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-10">
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs content-block">
                  <p>
                    An increasing number of our student population is made up of
                    international students. Making a payment into another
                    currency can be expensive for the student and leaves the
                    Educator with international receipt fees and difficulties
                    with payment reconciliation.
                  </p>
                  <p>
                    Our service allows your students to pay in their own
                    currency at the best exchange rates with low or no fees.
                    Payments are accepted via bank transfer or credit/debit
                    card. They are fully traceable for simple reconciliation and
                    as we pay you domestically, there will be no bank receipt
                    fees.
                  </p>
                  <p>
                    We can offer the widest range of currencies for your
                    students to pay in allowing as many as possible to benefit
                    from the savings.
                  </p>
                  <p>
                    Our service is free of charge to the Educator for receiving
                    payments and you will also be able to make outbound
                    international payments at far lower fees than your bank
                    would charge.
                  </p>
                  <p>
                    We are happy to visit and demonstrate how simple our
                    payments system is and how it can be added to your website
                    within an hour.
                  </p>
                  <p>
                    All transactions take place via our FCA registered and
                    regulated partner for your financial security.
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row bg-purple padding-top-40 padding-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col l24 m24 s24">
                <div className="box bs">
                  <h3>How can we help?</h3>

                  <div className="btn-inline">
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/contact-us"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Enquire Now </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link className="btn btn-primary animated-arrow" to="/">
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Register Company </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SchoolsUniversities;
